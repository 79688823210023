/* CSS Document */

.topLinks{
  vertical-align: middle;
      display: none;
      line-height: 12vmin;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #FFFFFF;
      padding-right: 3vmin;
      /* background: #2f3036; */
      text-decoration: none;

      float: right;
}

.App-header-landing {
    /*display: inline-block; */
}
.nav_containter {
  display: flex;
  justify-content: center;
  z-index: 1;
}
/*Strip the ul of padding and list styling*/
.nav_containter ul {
		list-style-type:none;
		margin:0;
		padding:0;
		position: absolute;
}

/*Create a horizontal list with spacing*/
li {
		display:inline-block;
		float: left;
		/*margin-right: 1px;*/
}

/*Style for menu links*/
li a {
		display:block;
		min-width:140px;
		height: 50px;
		text-align: center;
		line-height: 50px;
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		color: #FFFFFF;
		/* background: #2f3036; */
		text-decoration: none;
}

/*Hover state for top level links*/
li:hover a {
  outline: none;
		background: transparent;
    color: #FFF;
}

/*Style for dropdown links*/
li:hover ul a {
		background: transparent;
		color: #FFF;
    font-weight: bolder;
		height: 40px;
		line-height: 40px;
}

/*Hover state for dropdown links*/
li:hover ul a:hover {
		background: transparent;
		color: #31849B;
}

/*Hide dropdown links until they are needed*/
li ul {
		display: none;
}

/*Make dropdown links vertical*/
li ul li {
		display: block;
		float: none;
}

/*Prevent text wrapping*/
li ul li a {
		width: auto;
		min-width: 100px;
		padding: 0 20px;
		text-align:center;
}

/*Display the dropdown on hover*/
ul li a:hover + .hidden, .hidden:hover {
		display: block;
        width: max-content;
}

/*Style 'show menu' label button and hide it by default*/
.show-menu {
		font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
		text-decoration: none;
		color: #FFFFFF;
		/* background: #FFFFFF;*/
		text-align: center;
		padding: 10px 0;
		display: none;
}

/*Hide checkbox*/
input[type=checkbox]{
display: none;
}
/* Show menu when invisible checkbox is checked */
input[type=checkbox]:checked ~ #menu{
display: block;
}
ul#menu {
    /*display: inline-block; */
    z-index: 1;
}

/*Responsive Styles*/

@media screen and (max-width : 760px){
  menu_collapse
  {

  }
  .topLinks{
    display: inline;
    vertical-align: middle;

        line-height: 12vmin;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #FFFFFF;
        padding-right: 3vmin;
        /* background: #2f3036; */
        text-decoration: none;

        float: right;
  }
  ul#menu {
    /*Make dropdown links appear inline*/
  			position: static;
  			display: none;
        background-color: #f3f3f3;


    li{
      /*display: none;*/
      background-color: #f3f3f3;
      /*Create vertical spacing*/

      /* margin-bottom: 2px; */

      /*Make all menu links full width*/

      width: 100%;
        color: #31849B;
        a {
          width: 100%;
            color: #31849B;
            /*Display the dropdown on hover*/

            &:hover, .hidden, .hidden:hover {
                    width: 100%;
            }
         }
    }


}
	/*Display 'show menu' link*/
	.show-menu {
    justify-content: center;
    display: block;
    float: right;
    padding: 1.5vmin;
/*      padding-top: 1.5vmin;
  padding-left: 1.5vmin;
    padding-bottom: 3px;
    */
    font-size: 7vmin;
    /*border-color: #ffffff;
    border-width: .5px;
    border-style: solid; */
    /* padding: 4px; */
    /*border-radius: 4px;
    padding-left: 8px*/;
/*
    padding-right: 8px;
    line-height: 25px;
    margin-left: 20px;
    margin-top: 1.5vmin;
    margin-bottom: 13px;*/
	}
	/*Pointer on Hover, so that the user knows of an active link*/
	.show-menu:hover {
		cursor:pointer;
	}


  /*Center the text*/
	li ul#menu li a {
		text-align:center;
	}
  li ul#menu  li {
      display: block;
      float: left;
  }
  /*Style for menu links*/
  li a {
  		text-align: left;
      padding-left: 20px;

  }
  li:hover ul a {
    /* background: #f3f3f3; */
    /* color: #2f3036; */
    /* height: 40px; */
    line-height: 40px;
    /* width: 100%; */
  }
  .nav_containter {
    display: block;
    justify-content: center;
    z-index: 1;
  }
  .nav_containter ul {
  		position: static;
  }
  li ul li a {
      text-align: left;
  }

}
@media (pointer: coarse)
{
  li ul li a {
  		text-align: left;

  }
  li a {
    &:hover{
      background-color: transparent !important;

    }
    &:focus{
      background-color: transparent !important;

    }
  }
  .nav_containter ul {

  		position:relative;
  }

  .hidden, .hidden:hover {
          display:block;
  }
}
@media (pointer: coarse) and (orientation: landscape)
{
  li ul li a {
  		text-align: center;
  }
}
@media (hover:none),
(hover:on-demand) {
  nav a:hover {
    /* suppress hover effect on devices that don't support hover fully */
    background: none;
  }
}
@media screen and (max-height : 310px)
{
  /*
  .topLinks{
    display: inline;
    vertical-align: middle;

        line-height: 12vmin;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: #FFFFFF;
        padding-right: 10px;
        text-decoration: none;

        float: right;
  }*/
}
@media (prefers-color-scheme: dark) {
  ul#menu li a, ul#menu li{
    background-color: #000000;
    color: #a5ddf7; 
  }
}

