.App-logo {
  animation: App-logo-fade 0.5s linear;
  height: 10vmin;
}

.App-logo-small {
  animation: App-logo-fade 0.5s linear;
  position: relative;
    margin-top: -21px;
    top: 12px;
    height: 40px;
  /*height: 3vmin;*/
}
.App-logo-link{
  display: none;
  padding-top: 0.7vmin;
  width: 10vmin;
  height: 10vmin;

}
.App-ddn-logo{
  padding-top: 10px;


    height: 28px;

}
.App-logo-link{
  /*padding-right:2vmax;
  &:hover {
    background-color:  transparent;
  }*/
}

.App-logo-medium {
display: inline-block;
    margin:auto;
padding: 10px;
    height: 11vmin;
    width: 11vmin;
}

@media (max-width: 760px) and (orientation : portrait)
{
  .App-logo-small  {
      min-height: 40px;
      animation: App-logo-fade 0.5s linear;
      position: relative;
        margin-top: -21px;
        top: 12px;
        height: 40px;
  }
  .App-ddn-logo{
    display: none;
    animation: App-logo-fade 0.5s linear;
      height: 12vmin;

  }
  .App-ddn-logo-li{
    display: none;
    animation: App-logo-fade 0.5s linear;
      height: 12vmin;

  }

  .App-logo-link{
    display:inline;

    padding-top: 0.7vmin;
    padding-left: 2vmin;
      height: 10vmin;
      float: left;


  }
  .App-logo-link:hover {
    background-color:  transparent;
  }
  .ddn-logo-menu{
      height: 8.8vmin;
      width: 8.8vmin;
  }
  .App-title-small {
    animation: App-logo-fade 1s linear;
    width: 15vmin;
    display: none;
  }
  .App-logo-medium {
  display: inline-block;
      margin:auto;
  padding: 12px;
      height: 24vmin;
    /*height: 3vmin;*/
  }
}
@media (max-width: 760px) and (orientation : landscape)
{
  .App-logo-small  {
      min-height: 40px;
      animation: App-logo-fade 0.5s linear;
      position: relative;
        margin-top: -21px;
        top: 12px;
        height: 40px;
  }
  .App-ddn-logo-li{
    display: none;
    animation: App-logo-fade 0.5s linear;
      height: 12vmin;

  }
  .App-ddn-logo{
    animation: App-logo-fade 0.5s linear;
      height: 12vmin;
  }
  .App-logo-link{
    display:inline;

    padding-top: 0.7vmin;
    padding-left: 2vmin;
      height: 10vmin;
      float: left;

  }
  .App-logo-link:hover {
    background-color:  transparent;
  }
  .ddn-logo-menu{

      height: 8.8vmin;

  }
  .App-title-small {
    animation: App-logo-fade 1s linear;
    width: 15vmin;
    display: none;
  }
  .App-logo-medium {
  display: inline-block;
      margin:auto;
  padding: 12px;
      height: 24vmin;
    /*height: 3vmin;*/
  }
}

@media (min-width: 1921px)
{  .App-logo-medium {
  display: inline-block;
      margin:auto;
  padding: 12px;
      height: 6vmin;
    /*height: 3vmin;*/
  }

}
@media screen and (max-height : 310px)
{
  }
