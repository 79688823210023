.ddnFooter
{
  position: absolute;
  line-height: 24px;
  flex: 1;
  z-index: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  color: #fafafa;
  font-weight: 300;

  &:after
   {
     height: 130px;
   content: "";
   position: absolute;
   z-index: -1;
   bottom: 0;
   left: 0;
   right: 0;
   color: #fff;
  //  background:
  //      url('../../images/WEBDDNLOGObgmedium.svg') top 0 left 0 repeat,
  //      url('../../images/WEBDDNLOGObgmedium.svg') top 80px left 160px repeat;
   opacity: 0.05;
   /*background-position-x: 0%;
   background-position-y: 0%;
   background-size: 320px auto;()*/
   background-size: 320px 160px;
   }
  .ddnFooterDiv{
    position: relative;
 top: 33%;
  }
}
