a.ddnbutton
{

  display: flex;
justify-content: center;
width: 184px;
height: 40px;
padding: 0 24px;
font-size: 18px;
font-weight: 600;
line-height: 40px;
background-color: #FFF;
color: #186DA0;
border-radius: 48px;
box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
box-sizing: border-box;
cursor: pointer;
margin-bottom: -11px;
text-decoration: none;
text-transform: uppercase;
    overflow: hidden;
    border: none;
    font-family: 'Roboto', sans-serif;
display: inline-block;
}
a.ddnbutton:hover {
      opacity: .9;
  /*color: #6e6e6e;*/

}

button.ddn-button {
  /*width: 40%;*/
  margin-top: 2vmin;
  margin-bottom: 3vmin;
  border-radius: 8px;
  height: 6vmin;
  padding: 0 3vmin;
  font-size: 2vmin;
  color: #FFF;
  background-color: #186DA0;
  /* box-shadow: 0 2px 5px 0 rgba(0,0,0,.26); */
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-right: 20%;

}

.ddn-text-aligh-right {
  text-align: right;
}
.ddn-text-aligh-center {
  text-align: center;
}
.ddn-float-right {
  float:right;
}

.ddnbuttonContanier
{
/*text-align: right;
align:center;*/
 display: block;
}
@media screen and (max-width : 760px) , (pointer: coarse)
{
  button.ddn-button
  {
    height: 12vmin;
    font-size: 4vmin;
    margin-right: 2vmin;
  }
}
@media screen and (max-height : 310px)
{
  button.ddn-button
  {

      height: 30px !important;
      font-size: 16px;
      margin-right: 2vmin;


  }
}
