html
{
  -webkit-tap-highlight-color:transparent;
}
.App {
  text-align: center;
  background-color: #186DA0;
  min-height: 100vh;
  position: relative;
}
div#root {
    min-height: 100vh;
}
main{
  padding-bottom : 130px;
}


hr {
  border: none;
  background: #A5DDF7;
  height: 2px;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  width: 100vh;

  opacity: .5;
}
/*.App-logo-spin {
animation: App-logo-spin infinite 1s linear;
height: 10vmin;
}*/
.App-title-small {
animation: App-logo-fade 1s linear;
width: 15vmin;
}
.App-title-text-small {
height: 15px;
margin-bottom:  -1px;
}
.App-title {
animation: App-logo-fade 1s linear;
width: 40vmin;
}
.App-title-reg {
animation: App-logo-fade 0.3s linear;
width: 40vmin;
}
.App-icon-reg-landing {
animation: App-logo-fade 0.3s linear;
width: 100vmin;
  height: 50vmin;
}



.App-promo {
/*  width: 100vmin; */

padding: 13px 32px;
text-align: right;
}

.App-header
{
/* padding: 0.4em;
text-transform: lowercase;
top: 0;
left: 0;
position: fixed;
width: 100%;
 */
 position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  justify-content: flex-start;
padding-left: 10px;
padding: 0;
  height: auto;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
min-height: 56px;
    font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;
/* min-height: 100vh; */
/*
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
*/
color: white;
}
.App-main {
background-color: #186DA0;
min-height: 100vh;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(2.5vmin);
color: white;
}
.App-link {
color: #61dafb;
}
.web-headline {
  font-size: 20px;
  font-weight: 500;
  color: #186DA0;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Clarendon Blk BT';

}
.ddn-headline {
  font-size: 20px;
  font-weight: 500;
  color: #A5DDF7;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Clarendon Blk BT';

}
p{
display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-family: Roboto;
}

.ddnPower
{
display:inline;
font-family: 'Clarendon Blk BT';
font-size: 17px;

font-weight: 500;

color: #CCCCCD
}
.ddnPowerLogo
{
width:79px;
height:37px;
margin-bottom: -3px;
color: #000;
}

@keyframes App-logo-shrink {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}
@keyframes App-logo-fade {
from {
  opacity: 0;
}
to {
  opacity: 1;
}
}
@keyframes App-logo-spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}


@media (max-width: 1100px)
{
hr {
    border: none;
    background: #A5DDF7;
    height: 2px;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
    width: auto;
}
}

@media (max-width: 599px)
{
hr {
    border: none;
    background: #A5DDF7;
    height: 2px;
    display: block;
    unicode-bidi: isolate;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: auto;
    margin-inline-end: auto;
    overflow: hidden;
  width: auto;

}


.App-header
{
 position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  justify-content: flex-start;
padding-left: 10px;
padding: 0;
  height: auto;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
background-color: #48B6DE;
min-height: 56px;
    font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;

color: white;
}

.App-main {
background-color: #186DA0;
  margin-top: 56px;
    max-height: 486px;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}
.App-promo {
/*  width: 100vmin; */
order: 1;
padding: 16px 0px 0px 0px;
width: 90%;
}
.App-promo2 {
/*  does multiple images */
order: 1;
width: 100%;
padding-bottom: 0px;
margin-top: 0px;
margin-bottom: 0px;
}


p{
text-align:justify;
}

.App-title-reg-landing {
animation: App-logo-fade 0.3s linear;
width: 100%;
  height: 100%;
}


}

@media (max-width: 699px) and (orientation:landscape)
{
.text-headline {
    font-size: 19px;
}
}
@font-face {
  font-family: 'Clarendon Blk BT';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/ClarendonBT-Black.eot');
  src: url('./fonts/ClarendonBT-Black.eot?#iefix') format('embedded-opentype'),
      url('./fonts/ClarendonBT-Black.woff2') format('woff2'),
      url('./fonts/ClarendonBT-Black.woff') format('woff'),
      url('./fonts/Clrndnk.ttf') format('truetype'), /* Safari, Android, iOS */
      url('./fonts/ClarendonBT-Black.svg#ClarendonBT-Black') format('svg');
}

@font-face {
  font-family: 'Humnst777 Blk BT';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Humanist777BT-BlackB.eot');
  src: url('./fonts/Humanist777BT-BlackB.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Humanist777BT-BlackB.woff2') format('woff2'),
      url('./fonts/Humanist777BT-BlackB.woff') format('woff'),
      url('./fonts/Humanist777BT-BlackB.ttf') format('truetype'),
      url('./fonts/Humanist777BT-BlackB.svg#Humanist777BT-BlackB') format('svg');
}
