.ddnTextHeadlineInput
{
  font-family: 'Clarendon Blk BT';
  font-size: 5.2vmin;
  font-weight: 500;
  margin: 0px 0px 24px 16px;
  color: #CCCCCD;
  border: 0;
  background-color: transparent;
  padding: 0;
  outline: none;
  -webkit-appearance: none;
  margin-left: auto;
  margin-right: auto;
 display:block;
height: 1.79ch;
 min-width: -webkit-fill-available;
      text-align: center;
      mix-blend-mode: difference;
      margin-left: 16px;
      margin-bottom:24px;
      /*min-width: -webkit-fill-available;
      display: flex;
      align-items: center;
      justify-content: center;*/
}
.ddnDifference{
  mix-blend-mode: difference;
}
.input,
.textarea {
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}
