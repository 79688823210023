.ddnTextHeadline2
{
  font-family: 'Clarendon Blk BT';
  font-size: 5.2vmin;
  font-weight: 500;
  margin-top: 0vmin;
  padding-bottom: 2vmin;

  color: #CCCCCD;
  /*height: 1.79ch;*/
}
.ddnLogin
{
  padding-top:2vmin;
  Label {
      display: block;
      margin-right: auto;
      margin-left: auto;
    text-align: left;
      width: 40vmin;
    padding: 0 0 0 0;
    font-size: 2vmin;
    color: $dominate_color;
    box-sizing: border-box;
    text-decoration: none;
    overflow: hidden;
    border: none;
  /*  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
*/
  }


  .ddnErrorLabel{
    color: #d2f3ff;
    height:  3vmin;
    font-size: 2vmin;

    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  }
  input {
     &.errors{
           border-bottom: .3vmin solid red;
     }
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 2vmin;
      margin-bottom: 1vmin;


      width: 40vmin;
      border-top: none;
    border-left: none;
    border-right: none;

    border-bottom: .3vmin solid $dominate_color;
    /*
          border-radius: 8px;
          border: none;

    */
    height: 1.5vmin;
    padding: 3vmin;
    font-size: 2vmin;
    color: $dominate_color;
    /*
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);
    */
    box-sizing: border-box;

    text-decoration: none;
    overflow: hidden;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    &:focus{
      outline: none;
      background-color: $dominate_color3;
       color: #081E26;
    }
    &:-webkit-autofill,
    -webkit-autofill:hover,
    -webkit-autofill:focus,

 {
   border: none;
   -webkit-box-shadow: 0 0 0px 1000px white inset;

  -webkit-text-fill-color: #081E26;
  transition: background-color 5000s ease-in-out 0s;
      border-bottom: 0.3vmin solid #186DA0;
}
  }
  button {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 2vmin;
      margin-bottom: 3vmin;
      width: 40vmin;
      border-radius: 8px;
      height: 6vmin;
      padding: 0 3vmin;
      font-size: 2vmin;
      color: #FFF;
      background-color: $dominate_color;
      /*box-shadow: 0 2px 5px 0 rgba(0,0,0,.26);*/
      box-sizing: border-box;
      text-decoration: none;
      overflow: hidden;
      border: none;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
}
@media screen and (max-width : 760px) , (pointer: coarse)
{
  .ddnTextHeadline2
  {
    font-family: 'Clarendon Blk BT';
    font-size: 7vmin;
    font-weight: 500;
    margin-top: 3vmin;
    padding-bottom: 1.5vmin;

    color: #CCCCCD;
    /*height: 1.79ch;*/
  }
  .ddnLogin
  {
    padding-top:2vmin;
    Label {

        width: 90vmin;
        font-size: 4vmin;
        padding-top: 2vmin;

    }
    .ddnErrorLabel{
      color: #d2f3ff;
      height:  6vmin;
      font-size:4vmin;

    }
    input {

      height: 3vmin;

        width: 90vmin;
        font-size: 4vmin;

        margin-top: 4vmin;
        margin-bottom: 2vmin;
        height: 12vmin;


    }
    button {
        width: 90vmin;
        height: 12vmin;
        font-size: 4vmin;
      padding: 0 16px;
      &:focus{
        outline: none;
        background-color: lighten($dominate_color, 10)

      }

    }
    .ddnbuttonContanier{
      padding-bottom: 10vmin;
    }
  }
}

/*@media (pointer: coarse) and (orientation: landscape)
*/
/* most likely in landscape mode */
@media screen and (max-height : 310px)
{
  .ddnTextHeadline2
  {
    font-family: 'Clarendon Blk BT';
    font-size: 30px;
    font-weight: 500;
    margin-top: 40px;
    padding-bottom: 2vmin;

    color: #CCCCCD;
    /*height: 1.79ch;*/
  }
  .ddnLogin
  {      width: 100%;

    padding-top:.5vmin;
    Label {

      width: 40%;
        font-size: 16px;

    }
    .ddnErrorLabel{
      color: #d2f3ff;
      height: 30px !important;
      font-size: 16px;

    }
    input {
      border-bottom: 2vmin solid $dominate_color;

      &.errors{
            border-bottom: 2vmin solid red;
      }
      height: 40px !important;
          width: 40%;
          font-size: 16px;

    }
    .ddnbuttonContanier{
     width:100%;
    }
    button {
      width: 40%;

        height: 30px !important;
        font-size: 16px;


    }
  }
}
@media (prefers-color-scheme: dark) {
  .ddnLogin Label {
    background-color: #000000;
    color: #CCCCCD;
    
  }
  .ddnLogin button
  {
    background-color: #a5ddf7;
    color: #000000;
  }
}
