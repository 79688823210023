.text-headline {
    font-size: 3vmin;
    font-weight: 500;
    color: $dominate_color;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Humnst777 Blk BT';

}
.text-headline-no-caps {
    font-size: 20px;
    font-weight: 500;
    color: $dominate_color;
    margin-top: 10px;
    font-family: 'Humnst777 Blk BT';

}
.App-promo-text {
/*  width: 100vmin; */
max-width: 50%;
letter-spacing: .3px;
  padding: 0px 24px 24px;
text-align: left;
}
.App-title-reg-landing {

  animation: App-logo-fade 0.3s linear;
  width: 50vmin;
}
@media (prefers-color-scheme: dark) {
  .text-headline {
    color: #a5ddf7;
  }
  html, body {
    background-color: black;
  }

}

@media (max-width: 599px) and (orientation : portrait)
{
  .text-headline {
    text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: $dominate_color;
      margin-top: 1;
      text-transform: uppercase;
      font-family: 'Humnst777 Blk BT';

  }
  .App-promo-text {
    order: 2;
  /*  width: 100vmin; */
  letter-spacing: .3px;
  max-width: 100%;
  padding: 10px 16px 16px 16px;
  margin-top: 0px;
  margin-bottom: 0px;

  }
  .App-title-reg-landing {
    animation: App-logo-fade 0.3s linear;
    width: 100%;
  }
  .App-promo2 {
  /*  does multiple images */
  order: 1;
  width: 100%;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  }
}@media (prefers-color-scheme: dark) and (orientation : portrait) {
  .text-headline {
    color: #a5ddf7;
  }
  html, body {
    background-color: black;
  }
}
/*
@media (max-width: 899px) and (orientation : portrait)
{
  .App-title-reg-landing {

    animation: App-logo-fade 0.3s linear;
    width: 80vmin;
  }
}
*/
@media (min-width: 1921px)
{
  .text-headline {
    text-align: center;
    font-size: 1.5vmin;
    font-weight: 500;
    color: $dominate_color;
    margin-top: 10px;
    text-transform: uppercase;
    font-family: 'Humnst777 Blk BT';
    width: 30vmin
  }
  .App-main{
    font-size: 1.5vmin;

  }
  .App-promo-text {
  /*  width: 100vmin; */
/*  max-width: 50%; */
  letter-spacing: .3px;
    padding: 0px 24px 24px;
  text-align: left;

  }
  .App-title-reg-landing {

    animation: App-logo-fade 0.3s linear;
    width: 30vmin;
  }
  .App-promo2 {
  /*  does multiple images */
  display: contents;
  width: 30vmin;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;

  }
}
