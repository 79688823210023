.App-content-landing-container {
/* width: 100vmin; */
padding-top: 30px;
padding-left: 10px;
padding-right: 10px;
background-color: #48B6DE;

}
.App-content-landing {
/*  width: 100vmin; */

  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  max-width: 1500px;
  margin: auto;
}

@media (max-width: 1100px)
{
  .App-content-landing-container {
  /*  width: 100vmin; */
  margin-top: 0px;

  background-color: #48B6DE;
  }
}
@media (max-width: 599px)
{
  .App-content-landing {
  /*  width: 100vmin; */
  background-color: #48B6DE;
    color: white;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    box-sizing: border-box;


  }
  .App-content-landing-container {
  /*  width: 100vmin; */
  margin-top: 0px;

  background-color: #48B6DE;
  }
}
// @media (prefers-color-scheme: dark) and (max-width: 1100px) {
//   .App, .App-main, .App-content-landing-container, .App-content-landing, hr, body{
//     background-color: #000000;
//   }
  
// }
@media (max-width: 699px) and (orientation:landscape)
{
  .App-content-landing-container {
  padding-top: 20px;


  }
}
@media (prefers-color-scheme: dark) {
  .App, .App-main, .App-content-landing-container, .App-content-landing, hr, body{
    background-color: #000000;
  }
  
  .text-headline {
    color: #a5ddf7;
  }
  
}