header {
  height: 55px;
}
@media (max-width: 599px){
/*
  .App-header-landing
  {
     position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 10;
      justify-content: flex-start;
      padding-left: 10px;
      padding: 0;
      height: auto;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      min-height: 56px;
      font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;

    color: white;
  }
  */
}

@media (max-width: 760px) and (orientation : portrait)
{
  .App-header-landing
  {
    height: 12vmin;
  }
}
@media (pointer: coarse)
{
  .App-header-landing
  {
    height: 12vmin;
  }
/*
  header {
    height:auto;
  }
  */
}
